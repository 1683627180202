/* Ember supports plain CSS out of the box. More info: https://cli.emberjs.com/release/advanced-use/stylesheets/ */

/*
 * Tells the electron browser that we can render in both light and dark mode.
 * This mostly helps make scrollbars the right color in dark mode.
 */
:root {
  color-scheme: light dark;
}

/* Makes the electron window draggable since we disable the normal title bar */

#topDraggableMenuBar {
  -webkit-app-region: drag;
}

input,
select,
textarea,
button,
a {
  -webkit-app-region: no-drag;
}

/* A bug fix for powerselect */
.ember-power-select-selected-item,
.ember-power-select-placeholder {
  display: inline-block;
}

.ember-power-select-trigger {
  display: flex;
  align-items: center;
}

/* Some copy/paste styles for the bounce player */

.ui-button {
  @apply inline-flex items-center px-3 py-1 text-lg border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.stereo-player {
  @apply flex flex-col content-center mx-auto /*rounded-t-lg lg:rounded-b-lg*/ place-items-center font-mono text-sm;
}

.stereo-player-title {
  @apply text-left w-full;
}

.stereo-player-main {
  @apply w-full flex flex-row content-center gap-4 p-0 mx-auto rounded place-items-center;
}

.stereo-player-position {
  @apply flex items-center flex-grow w-full gap-2 whitespace-nowrap;
}

.stereo-player-position-slider {
  @apply relative  w-full flex items-center justify-start rounded h-2;
}

.stereo-player-position-thumb {
  @apply absolute h-4 w-4 flex rounded-lg shadow-sm;

  top: 15px;
}

.stereo-player-position-progress {
  @apply h-2 flex rounded;
}

.stereo-player input[type="range"] {
  /* @apply bg-on-surface-2; */
}

.stereo-player-controls {
  /* @apply grid grid-cols-1 gap-2 place-items-center min-w-max; */
  @apply flex items-center flex-grow gap-2 place-items-center min-w-max;
}

.stereo-player-errors {
  @apply px-2 text-left text-red-500;
}

.stereo-volume {
  /* Not sure what's making it so that we need this. But it works... */
  height: 24px;
}

.stereo-player-volume {
  @apply flex items-stretch items-center content-center gap-2;
}

.stereo-button {
  @apply inline-flex items-center px-1 py-1 text-base font-medium border rounded-md shadow-none focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.stereo-button:disabled,
.ui-button:disabled {
  @apply opacity-50 pointer-events-none;
}

/* some customizations for wavesurfer.js */
.wavesurfer ::part(minimap) {
  margin-bottom: 0.5em;

  /* padding-top:0.5em; */
}

.wavesurfer ::part(wrapper),
.wavesurfer ::part(progress) {
  padding-top: 1em;
}

.wavesurfer-small ::part(wrapper),
.wavesurfer-small ::part(progress) {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.wavesurfer ::part(marker) {
  background-color: rgb(0 255 0 / 25%) !important;
  font-size: 12px;
  text-shadow: 0 0 2px #fff;
  border-left: 4px solid rgb(0 255 0);
}

.wavesurfer ::part(region) {
  background-color: rgb(0 0 255 / 25%) !important;
  font-size: 12px;
  text-shadow: 0 0 2px #fff;
  border-left: 4px solid rgb(0 0 255);
}

.wavesurfer-small ::part(region) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: calc(100% - 1em);
}

.wavesurfer ::part(region) img {
  border-radius: 100%;
}

.wavesurfer ::part(comment-marker) {
  background-color: rgb(255 0 0 / 25%) !important;
  font-size: 12px;
  text-shadow: 0 0 2px #fff;
  border-left: 4px solid rgb(255 0 0);
}

.wavesurfer ::part(region-content) {
  margin-top: 0;
}

@tailwind base;

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  a {
    @apply text-blue-600 underline;
  }
}

.compact-prose:first-child {
  margin-top: 0;
}

.compact-prose:last-child {
  margin-bottom: 0;
}

@tailwind components;
@tailwind utilities;

/**************************************
 * Some global overrides
 **************************************/
a {
  @apply text-emerald-600 dark:text-emerald-400;
}

a:hover {
  @apply text-emerald-700 dark:text-emerald-500;
}
